import React, { Component, useState  } from 'react';
import PropTypes from 'prop-types';
import MintButton from '../MintButton/MintButton';
import previewGif from './images/preview.gif';
const propTypes = {
    maxMintCount: PropTypes.string,
    currentMintCount: PropTypes.string,
    notConnected: PropTypes.bool,
    nftSymbol: PropTypes.string,
    networkSymbol: PropTypes.string,
    price: PropTypes.number,
    mint: PropTypes.func,
    text: PropTypes.string,
    claiming: PropTypes.bool
}

const defaultProps = {
    maxMintCount: '10000',
    currentMintCount: '0',
    notConnected: true,
    nftSymbol: 'JAC',
    networkSymbol: 'ETH',
    price: 0.0,
    text: 'MINT',
    claiming: false
}



export default class NFTMinter extends Component {
    constructor(props) {
        super(props);
        this.state = { currentMintCount: '', mintAmount: 1 };
        this.handleMint = this.handleMint.bind(this);
        this.handleDecrement = this.handleDecrement.bind(this);
        this.handleIncrement = this.handleIncrement.bind(this);
    }

    componentDidMount() {
        // console.log('PREV PROPS', prevProps)
        // if (this.props.currentMintCount !== prevProps.currentMintCount) {
            this.setState({
                currentMintCount: this.props.currentMintCount
            });
        // }

    }
    componentDidUpdate(prevProps) {
        
    }


    onMint() {
        if (this.props.mint) {
            this.props.mint(this.state.mintAmount)
        }
    }
    handleMint() {
        this.props.onMint()
    }

    handleIncrement() {
        if(this.state.mintAmount >= 3) {
            return;
        }
        this.setState({
            mintAmount: this.state.mintAmount + 1
        });
    }

    handleDecrement() {
        if(this.state.mintAmount <= 0) {
            return;
        }
        this.setState({
            mintAmount: this.state.mintAmount - 1
        });
    }

    

    render() {
        return <div className="App--content">
            <p className="App--content__header">
                {`1 ${this.props.nftSymbol} costs ${this.props.price} ${this.props.networkSymbol}`}
            </p>
            <div className="App--content__preview-container">
                <img className="App--content__preview-container--preview"
                    src={previewGif}></img>
            </div>
            <div className='App--content__incrementer'>
                <button className='glow-on-hover font-size-xx' onClick={() => this.handleDecrement()}>-</button>
                <h2 className="App--content__incrementer--current-count">{this.state.mintAmount}</h2>
                <button className='glow-on-hover font-size-xx' onClick={() => this.handleIncrement()}>+</button>
            </div>
            {
                !this.props.notConnected &&
                <div>
                    <p>
                        {`${this.state.currentMintCount} / ${this.props.maxMintCount}`}
                    </p>
                    <p>
                        {
                            !this.props.claiming ?
                                <button
                                    className='glow-on-hover'
                                    onClick={this.handleMint}>
                                    {this.props.text}
                                </button>
                                :
                                <p> CLAIMING NFT </p>

                        }


                    </p>
                </div>
            }
        </div>
    }
}

NFTMinter.propTypes = propTypes;
NFTMinter.defaultProps = defaultProps;