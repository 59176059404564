import React, {Component} from 'react';
import PropTypes from 'prop-types';
const propTypes = {
    text: PropTypes.string
}

const defaultProps = {
    text: 'Custom Button'
}

export default class MintButton extends Component {
    render() {
        return <button className='glow-on-hover'>{this.props.text}</button>
    }
}

MintButton.propTypes = propTypes;
MintButton.defaultProps = defaultProps;