import React, {Component} from 'react';
import PropTypes from 'prop-types';

const roadmapItems = [
    {
        date: 'Goal #1',
        message: 'Sell 10k Dope Ladies'
    },
    {
        date: 'Goal #2',
        message: 'Build the community. Grow the community to 50k followers'
    },
    {
        date: 'Goal #3',
        message: 'Create the second Generation'
    }
];
export default class Roadmap extends Component {
    constructor(props) {
        super(props);
        this.state = {currentMintCount:''};
    }

    render() {
        return <div className="App--content">
            <h2>Roadmap</h2>
                    <ul>
                        {
                            roadmapItems.map( item => <li>{item.message}</li>)
                        }
                    </ul>
            
                </div>
    }
}
