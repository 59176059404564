import React, {Component} from 'react';
import PropTypes from 'prop-types';

const aboutusItems = [
    {
        name: 'The Intelligent Bear',
        socialLinks: [
            {
                channel: 'instagram',
                link: 'https://instagram.com/intelligentbear'
            }
        ],
        image: [],
        date: '',
        message: 'I love to develop and create art. Always striving to get better at both. Thank you for taking the time to check out our NFT Collection. I hope to grow this project into something inspiring.'
    } 
];
export default class AboutUs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
        <div className="App--content">
            <h2>About Us</h2>
            <ul>
                {
                    aboutusItems.map( item => <li>
                        <div className='App--content__about_profile_container'>
                            <h3>{item.name}</h3>
                            <div><img src={item.image}></img></div>
                            <p>{item.message}</p>
                        </div>
                        
                        </li>)
                }
            </ul>
        </div>)
    }
}
