import logo from './logo.svg';
import './App.scss';
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import MintButton from './MintButton/MintButton';
import SocialMediaBar from './SocialMediaBar/SocialMediaBar';
import NFTMinter from './NFTMinter/NFTMinter';
import Footer from './Footer/Footer';
import ReactGA from 'react-ga';
import Roadmap from './Roadmap/Roadmap';
import AboutUs from './AboutUs/AboutUs';

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  React.useEffect(() => {

    ReactGA.initialize("UA-217906867-1",{
      'cookieDomain': 'auto',
      // 'debug': true
    });
    ReactGA.pageview(window.location.pathname);

    // if(ReactGA.hasLoaded()) {
    //   console.log('loaded')
    // }
  },[])

  const needToBeConnected = blockchain.account === "" ||
  blockchain.smartContract === null;

  const onMint = async () => {
    console.log('MINTING');
    claimNFTs();
  }
  return (
    <div className="App">
      <h1 className='neonText'> {CONFIG.NFT_NAME}</h1>
      {needToBeConnected &&
        <div className="App--content"> 
          <h2>Connect to the {CONFIG.NETWORK.NAME} network </h2>
          {blockchain.errorMsg &&
          <p>{blockchain.errorMsg}</p>
          }
          <p>
            <button
              className='glow-on-hover'
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
              CONNECT
            </button>
          </p>
        </div>
      }

      <NFTMinter 
        currentMintCount={data.totalSupply.toString()}
        notConnected={needToBeConnected}
        price={CONFIG.DISPLAY_COST}
        nftSymbol={CONFIG.SYMBOL}
        claiming={claimingNft}
        mint={(evt) => {onMint(evt)}}>
      </NFTMinter>
      <Roadmap></Roadmap>
      <AboutUs></AboutUs>
      <Footer></Footer>
      <SocialMediaBar></SocialMediaBar>
    </div>
  );
}


export default App;
