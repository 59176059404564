import React, {Component} from 'react';
import PropTypes from 'prop-types';
const propTypes = {
    supportedMedia: PropTypes.array
}

const defaultProps = {
    supportedMedia: [{
                        channel:'twitter', 
                        link: 'https://www.twitter.com/dopeladyclub',
                        linkText: '🐦 /dopeladyclub'
                    },
                    {
                        channel:'instagram', 
                        link: 'https://www.instagram.com/dopeladyclub',
                        linkText: '📷/dopeladyclub'
                    },
                    {
                        channel:'discord', 
                        link: 'https://discord.gg/qU6PKadPYZ',
                        linkText: 'Discord'
                    }]
}
const discordLink = '';

export default class SocialMediaBar extends Component {
    render() {
       let list= this.props.supportedMedia.map((media, index) =>
             <li key={index}>
                 <a target="_blank" href={media.link}>
                     <h2 className='neonText'>{media.linkText}</h2>
                </a>
            </li>
        );

        return <ul className='App--content__social-buttons'>{list}</ul>
    }
}

SocialMediaBar.propTypes = propTypes;
SocialMediaBar.defaultProps = defaultProps;