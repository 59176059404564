import React, {Component} from 'react';
import PropTypes from 'prop-types';
const propTypes = {
    networkName: PropTypes.string,
    gasLimit: PropTypes.string
}

const defaultProps = {
    networkName: 'Ethereum',
    gasLimit: '28500000'
}

let checkNetwork = '';
let gasDisclaimer = '';
export default class Footer extends Component {
     render() {
        checkNetwork = `Always make sure you are connected to the right network (${this.props.networkName} Mainnet) and the correct address. Please note: Once you make the purchase, you cannot undo this action.`;
        gasDisclaimer = `We have set the gas limit to ${this.props.gasLimit} for the contract to
        successfully mint your NFT. We recommend that you don't lower the
        gas limit.`
        return  (<div className='App--content__footer'>
                    <p>
                        {checkNetwork}
                    </p>
                    <p>
                        {gasDisclaimer}
                    </p>


                </div>)
        
    }
}
Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;